<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <template v-if="!loadingLandingPage">
      <main role="main" class="flex-shrink-0 mb-5 main-leaderboard">
        <div class="container bg-white" :style="`max-width:${maxWidth}`">
          <div class="row">
            <div class="col-lg-9 align-self-center">
              <div class="row">
                <div class="col-sm-3 align-self-center text-center">
                  <div class="font-size-17">
                    {{leaderboard.business.business_name}}
                  </div>
                </div>
                <div class="col-sm-9 text-center text-uppercase">
                  <h1>Leader Board</h1>
                </div>
              </div>
            </div>
            <div class="col-lg-3 green-bg text-white p-2">
              <CountDownTimer :end-date="new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59)" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-9 grey-bg text-center">
              <div class="row mt-3">
                <div class="col-sm-4">
                  <div class="card mb-1">
                    <h5>
                      New Reviews This Month
                    </h5>
                    <div class="d-flex align-items-center">
                      <table class="w-100 progess-table w-bars mb-2">
                        <tr>
                          <td width="20">5</td>
                          <td>
                            <b-progress variant="warning" :max="maxMonthStars" :value="leaderboard.monthly_rating['5']" height="5px" />
                          </td>
                        </tr>
                        <tr>
                          <td width="20">4</td>
                          <td>
                            <b-progress variant="warning" :max="maxMonthStars" :value="leaderboard.monthly_rating['4']" height="5px" />
                          </td>
                        </tr>
                        <tr>
                          <td width="20">3</td>
                          <td>
                            <b-progress variant="warning" :max="maxMonthStars" :value="leaderboard.monthly_rating['3']" height="5px" />
                          </td>
                        </tr>
                        <tr>
                          <td width="20">2</td>
                          <td>
                            <b-progress variant="warning" :max="maxMonthStars" :value="leaderboard.monthly_rating['2']" height="5px" />
                          </td>
                        </tr>
                        <tr>
                          <td width="20">1</td>
                          <td>
                            <b-progress variant="warning" :max="maxMonthStars" :value="leaderboard.monthly_rating['1']" height="5px" />
                          </td>
                        </tr>
                      </table>
                      <div class="p-2 big-numbers">
                        {{maxMonthStars}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="card mb-1">
                    <h5>
                      Total Card Taps
                    </h5>
                    <table>
                      <tr>
                        <td class="big-numbers">
                          {{leaderboard.this_months_clicks}}
                          <small class="d-block">This Month</small>
                        </td>
                        <td class="big-numbers">
                          {{leaderboard.last_months_clicks}}
                          <small class="d-block">Last Month</small>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="card mb-1">
                    <h5>
                      Overall Rating
                    </h5>
                    <div class="d-flex align-items-center">
                      <table class="w-100 progess-table w-bars mb-2">
                        <tr>
                          <td width="20">5</td>
                          <td>
                            <b-progress variant="warning" :max="leaderboard.total" :value="leaderboard.overall_rating['5']" height="5px" />
                          </td>
                        </tr>
                        <tr>
                          <td width="20">4</td>
                          <td>
                            <b-progress variant="warning" :max="leaderboard.total" :value="leaderboard.overall_rating['4']" height="5px" />
                          </td>
                        </tr>
                        <tr>
                          <td width="20">3</td>
                          <td>
                            <b-progress variant="warning" :max="leaderboard.total" :value="leaderboard.overall_rating['3']" height="5px" />
                          </td>
                        </tr>
                        <tr>
                          <td width="20">2</td>
                          <td>
                            <b-progress variant="warning" :max="leaderboard.total" :value="leaderboard.overall_rating['2']" height="5px" />
                          </td>
                        </tr>
                        <tr>
                          <td width="20">1</td>
                          <td>
                            <b-progress variant="warning" :max="leaderboard.total" :value="leaderboard.overall_rating['1']" height="5px" />
                          </td>
                        </tr>
                      </table>
                      <div class="p-2 big-numbers">
                        {{ leaderboard.average.toFixed(1) }}
                      </div>
                    </div>
                    <div class="total-reviews">{{leaderboard.total}} Total Reviews</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <table v-if="leaderboard.current_employees.length" class="table text-left rank-table font-size-20 font-weight-bold">
                    <thead>
                      <tr>
                        <th class="border-0">
                          Rank
                        </th>
                        <th class="border-0">
                          Name
                        </th>
                        <th class="text-right border-0">
                          Review Requests
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(obj,i) in leaderboard.current_employees" :key="i" :class="i >= visibleRows ? 'd-none' : 'visible'">
                        <td>
                          #{{i + 1}}
                        </td>
                        <td>
                          {{obj.name}}
                        </td>
                        <td class="text-right">
                          {{obj.count}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button v-if="visibleRows < leaderboard.current_employees.length" id="employee-load" type="button" class="btn px-5 green-bg" @click="loadMore">Load more</button>
                </div>
              </div>
            </div>
            <div class="col-sm-3 blue-bg text-white text-center position-relative">
              <h3 class="text-uppercase text-white">Past Winners</h3>
              <ul class="p-0 list-unstyled">
                <li v-for="(name,month) in leaderboard.previous_winners" :key="month" class="mt-4">
                  <span class="d-flex">
                    <img src="/images/trophy-img.png" :alt="`${month}`"/>
                    <span class="pl-3">
                      <h5 class="text-white text-left mb-1">{{ name }}</h5>
                      <span class="d-block text-left">{{ month }}</span>
                    </span>
                  </span>
                </li>
              </ul>
              <div class="position-absolute powered-by">
                <!-- This will be at the bottom right of the column -->
                <h6 v-if="leaderboard.business.agency.settings.powered_by_link" class="mb-2 text-white">
                  <a :href="leaderboard.business.agency.settings.powered_by_link" target="_blank" class="text-white">Powered by: {{leaderboard.business.agency.name}}</a>
                </h6>
                <h6 v-else class="mb-2 text-white">Powered by: {{leaderboard.business.agency.name}}</h6>
              </div>
            </div>
          </div>
        </div>
      </main>
    </template>
    <div v-else-if="status === 404">
      <h1 class="text-center">Not Found</h1>
    </div>
    <div v-else class="text-center mt-4">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>

import CountDownTimer from "@components/Common/CountDownTimer.vue";


export default {
  components:{
    CountDownTimer
  },
  props:{
    uuid:{
      default:null,
      type:String
    },
    visibleRowsDefault:{
      default:6,
      type:Number
    },
    maxWidth:{
      default:'1000px',
      type:String
    },
    minHeight:{
      default:'100vh',
      type:String
    }
  },
  data() {
    return {
      loadingLandingPage: false,
      status:200,
      visibleRows:this.visibleRowsDefault,
      leaderboard: {
        this_months_clicks:0,
        last_months_clicks:0,
        average:0,
        overall_rating:{
          1:0,
          2:0,
          3:0,
          4:0,
          5:0
        },
        monthly_rating:{
          1:0,
          2:0,
          3:0,
          4:0,
          5:0
        },
        total:0,
        previous_winners:{},
        business:{
          agency:{
            name:null,
            landingpage:{
              logo:null
            }
          }
        },
        current_employees:{}
      }
    }
  },
  metaInfo () {
    return {
      title: 'Review Leaderboard - ' + this.dataBusiness?.business.business_name,
      meta: [
        { name: 'og:title', content: 'Review Leaderboard - ' + this.dataBusiness?.business.business_name },
      ],

    }
  },
  computed: {
    maxMonthStars(){
      return Object.values(this.leaderboard.monthly_rating).reduce((acc, curr) => acc + curr, 0);
    },
  },

  mounted() {
    if(!this.business) {
      this.getData();
    }else{
      this.dataBusiness = this.business;
    }
  },

  methods: {
    loadMore(){
      this.visibleRows += this.visibleRowsDefault;
    },
    getData() {
      // eslint-disable-next-line camelcase
      let uniqueId = this.$route.params.uuid;
      if(this.uuid){
        uniqueId = this.uuid;
      }
      this.$store
          .dispatch('landingPage/getLeaderBoard',uniqueId)
          .then((res) => {
            console.log(res);
            this.leaderboard = res;
          })
          .catch((error) => {
            if(error.response){
              this.status = error.response.status;
            }
          })
    },
  },
}
</script>
<style>
@import "https://fonts.googleapis.com/css2?family=Raleway&display=swap";
</style>
<style lang="scss" scoped>
.container{
  .green-bg{
    background: #4bad62;
  }
  .blue-bg{
    background: #0271be
  }
  .grey-bg{
    background: #d0d0d1;
  }
  .big-numbers{
    color: #4bad62;
    font-size: 2.5em;
    font-weight: bold;
    small{
      color: #737373;
      font-size: 14px;
    }
  }
  .progess-table{
    font-size: 9px;
  }
  table.w-bars{
    font-size: 9px !important;
  }
  .total-reviews{
    margin-top: -8px;
    font-size: .9em;
  }
  .card{
    min-height: 140px;
  }
  .rank-table{
    tbody{
      tr{
        background: #e1e3e6;
        color: #737373;
        /*display: none;*/
      }
      tr:nth-child(1){
        background: #0271be;
        color: #fff;
      }
      tr:nth-child(2){
        background: #bc1823;
        color: #fff;
      }
      tr:nth-child(3){
        background: #fff;
      }
      tr.active{
        display: table-row;
      }
    }
  }
  #employee-load{border-radius: 0;margin-bottom:20px}
  .powered-by{
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
  .blue-bg.position-relative{
    padding-bottom: 50px;
  }
}
.main-leaderboard{
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
}
</style>
<style>
body{
  padding-bottom:0;
}
@media screen and (max-width:640px) {
  body {
    background: #fff;
  }
}
</style>
